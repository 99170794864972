// Manually changed from events.json.
// TODO: Must update to be dynamically set from events.json
// TODO: in events.json, add BGImg, firestorePath, and posterImage paths.
const eventsData = [
  // {
  //   title: "Test Event",
  //   date: "09/29/2024",
  //   time: "18:30 PDT",
  //   location: "HUB 145",
  //   text: "Insert information about Test Event here.",
  //   BGImg: "",
  //   firestorePath: "",
  //   posterImage: "",
  //   img: [
  //     {
  //       src: "../images/events/seathrough/2024/seathrough2024_card.png",
  //       alt: "Card front image",
  //     },
  //     { src: "../images/events/keraton/2022/3.jpeg" },
  //     { src: "../images/events/keraton/2022/4.jpeg" },
  //     { src: "../images/events/keraton/2022/5.jpeg" },
  //   ],
  //   completed: false,
  // },
  {
    title: "Seathrough",
    date: "09/28/2024",
    time: "18:30 PDT",
    location: "HUB 145",
    text: "Insert information about Seathrough here.",
    img: [
      {
        src: "../images/events/seathrough/2024/seathrough2024_card.png",
        alt: "Card front image",
      },
      { src: "../images/events/keraton/2022/3.jpeg" },
      { src: "../images/events/keraton/2022/4.jpeg" },
      { src: "../images/events/keraton/2022/5.jpeg" },
    ],
    completed: false,
  },
  {
    title: "Seattle Stamp Quest",
    date: "09/23/2024",
    time: "14:00 PDT",
    location: "Red Square",
    text: "Seattle Stamp Quest is an exciting scavenger hunt event that takes participants on an adventure across the city. This event is a fun-filled experience where you can meet new friends, complete activities, and win amazing prizes!",
    img: [
      {
        src: "../images/events/stampquest/seattle_stamp_quest_card.png",
        alt: "Card front image",
      },
      { src: "../images/events/stampquest/stamp_quest_1.jpg" },
      { src: "../images/events/stampquest/stamp_quest_2.jpg" },
      { src: "../images/events/stampquest/stamp_quest_3.jpg" },
      { src: "../images/events/stampquest/stamp_quest_4.jpg" },
      { src: "../images/events/stampquest/stamp_quest_5.jpg" },
      { src: "../images/events/stampquest/stamp_quest_6.jpg" },
      { src: "../images/events/stampquest/stamp_quest_7.jpg" },
      { src: "../images/events/stampquest/stamp_quest_8.jpg" },
      { src: "../images/events/stampquest/stamp_quest_9.jpg" },
    ],
    completed: true,
  },
  {
    title: "Winter Ball",
    date: "02/24/2023",
    time: "18:00 PDT",
    location: "HUB North Ballroom, UW",
    text: "Get your suits and dresses ready and get lost in your dance in ISAUW’s Winter Ball. Be ready for a night of love, laughter, and beautiful pictures as you get serenaded by live music. A three-course meal is provided as we sway you into a magical night. ",
    img: [
      {
        src: "../images/events/winter ball/2023/1.jpeg",
        alt: "Card front image",
      },
      { src: "../images/events/winter ball/2023/3.jpeg" },
      { src: "../images/events/winter ball/2023/4.jpeg" },
      { src: "../images/events/winter ball/2023/2.jpeg" },
      { src: "../images/events/winter ball/2023/5.jpeg" },
      { src: "../images/events/winter ball/2023/6.jpeg" },
    ],
    completed: true,
  },
  {
    title: "Social Night: Indomie Eating Competition",
    date: "04/12/2023",
    time: "12:00 PDT",
    location: "HUB 250, UW",
    text: "A Spring Quarter kick off collaboration event with Indomie USA, where students can participate in an Indomie eating competition to win prizes and meet others!",
    img: [
      {
        src: "../images/events/socialnight/2023/indomie-1.jpeg",
        alt: "Card front image",
      },
      { src: "../images/events/socialnight/2023/indomie-2.jpeg" },
      { src: "../images/events/socialnight/2023/indomie-3.jpeg" },
      { src: "../images/events/socialnight/2023/indomie-4.jpeg" },
      { src: "../images/events/socialnight/2023/indomie-5.jpeg" },
    ],
    completed: true,
  },
  {
    title: "Keraton",
    date: "05/06/2023",
    time: "16:00 PDT",
    location: "Hub Lawn, UW",
    text: "Keraton is an annual Indonesian cultural festival hosted by ISAUW (Indonesian Student Association at University of Washington) with a purpose to introduce the rich culture of Indonesia to the greater Seattle Community. This event is a free 'one day pass to Indonesia' filled with food, games, music and performances.",
    img: [
      { src: "../images/events/keraton/2022/6.jpeg", alt: "Card front image" },
      { src: "../images/events/keraton/2022/2.jpeg" },
      { src: "../images/events/keraton/2022/3.jpeg" },
      { src: "../images/events/keraton/2022/4.jpeg" },
      { src: "../images/events/keraton/2022/5.jpeg" },
      { src: "../images/events/keraton/2022/1.jpeg" },
    ],
    completed: true,
  },
  {
    title: "Friendsgiving",
    date: "11/19/2022",
    time: "18:00 PDT",
    location: "The M Seattle, U District",
    text: "An interactive social gathering where we will be serving “Thanksgiving” - themed dinner, karaoke and drinks served in a bar for those 21+. This event aims to serve as an opportunity to de-stress, relax and have some quality time with your friends in the days leading up to the Thanksgiving break.",
    img: [
      {
        src: "../images/events/friendsgiving/2022/1.jpeg",
        alt: "Card front image",
      },
      { src: "../images/events/friendsgiving/2022/22.jpeg" },
      { src: "../images/events/friendsgiving/2022/3.jpeg" },
      { src: "../images/events/friendsgiving/2022/4.jpeg" },
      { src: "../images/events/friendsgiving/2022/5.jpeg" },
    ],
    completed: true,
  },
  {
    title: "Social Night",
    date: "04/06/2022",
    time: "19:00 PDT",
    location: "HUB, UW",
    text: "A night of mingling to form new bonds and rekindle old ones. Get a chance to win prizes, merchandise, food, and a sneak peak of our upcoming events. Enjoy yourself, and let us know what you'd want to see next.",
    img: [
      {
        src: "../images/events/socialnight/2021/1.jpg",
        alt: "Card front image",
      },
    ],
    completed: true,
  },
  {
    title: "SeaRace",
    date: "09/26/2022",
    time: "13:30 PDT",
    location: "Dahl Playground & Wading Pool",
    text: "A fun-filled day of adventure filled with traditional Indonesian Independence Day games, where you and your team will have to cooperate to be the fastest team to complete each race!",
    img: [
      { src: "../images/events/searace/2022.jpg", alt: "Card front image" },
      { src: "../images/events/searace/searace2.jpg" },
      { src: "../images/events/searace/searace3.jpg" },
      { src: "../images/events/searace/searace4.jpg" },
    ],
    completed: true,
  },
  {
    title: "Seathrough",
    date: "10/01/2022",
    time: "18:00 PDT",
    location: "Husky Union Building, UW",
    text: "Our annual dinner party that welcomes you to the IndoHuskies Community. An event that you do not want to miss out on as we’ll be providing delicious Indonesian food, an opportunity to create new friends, and win one-of-kind prizes by playing our games!",
    img: [
      { src: "../images/events/seathrough/2022.jpg", alt: "Card front image" },
      { src: "../images/events/seathrough/seathrough2.jpeg" },
      { src: "../images/events/seathrough/seathrough3.jpeg" },
      { src: "../images/events/seathrough/seathrough4.jpeg" },
      { src: "../images/events/seathrough/seathrough5.jpg" },
    ],
    completed: true,
  },
  {
    title: "Seattle 101",
    date: "08/14/2022",
    time: "13:00 PDT",
    location: "Jakarta, Indonesia",
    text: "Seattle 101 is our annual informative session designed to help prepare you to adjust to college life as easily as possible! This event aims to help you learn more about Seattle culture and the necessary tasks you have to do after arriving. Additionally, you will have the opportunity to learn about the experiences of our presenters, find and meet new friends during our networking session, and ask your burning questions during our QnA session, such as:  “Which bank should I register for?” “What’s the weather like?” “Where do people go to have fun?” etc.",
    img: [
      {
        src: "../images/events/seattle 101/seattle101.jpg",
        alt: "Card front image",
      },
      { src: "../images/events/seattle 101/seattle101 2.jpg" },
    ],
    completed: true,
  },
  {
    title: "Winter Ball",
    date: "02/14/2022",
    time: "--",
    "location PDT": "IMA, UW",
    text: "Get your suits and dresses ready and get lost in your dance in ISAUW’s Winter Ball. Be ready for a night of love, laughter, and beautiful pictures as you get serenaded by live music. A three-course meal is provided as we sway you into a magical night. ",
    img: [
      {
        src: "../images/events/winter ball/2019/1.jpg",
        alt: "Card front image",
      },
      { src: "../images/events/winter ball/2019/3.jpg" },
      { src: "../images/events/winter ball/2019/4.jpg" },
      { src: "../images/events/winter ball/2019/5.jpg" },
      { src: "../images/events/winter ball/2019/2.jpg" },
    ],
    completed: true,
  },
  {
    title: "Keraton",
    date: "05/07/2022",
    time: "16:00 PDT",
    location: "Rainier Vista, UW",
    text: "Keraton is an annual Indonesian cultural festival hosted by ISAUW (Indonesian Student Association at University of Washington) with a purpose to introduce the rich culture of Indonesia to the greater Seattle Community. This event is a free 'one day pass to Indonesia' filled with food, games, music and performances.",
    img: [
      { src: "../images/events/keraton/2022/1.jpeg", alt: "Card front image" },
      { src: "../images/events/keraton/2022/2.jpeg" },
      { src: "../images/events/keraton/2022/3.jpeg" },
      { src: "../images/events/keraton/2022/4.jpeg" },
      { src: "../images/events/keraton/2022/5.jpeg" },
      { src: "../images/events/keraton/2022/6.jpeg" },
    ],
    completed: true,
  },
  {
    title: "Friendsgiving",
    date: "11/19/2021",
    time: "18:30 PDT",
    location: "The M Seattle, U District",
    text: "An interactive social gathering where we will be serving “Thanksgiving” - themed dinner, karaoke and drinks served in a bar for those 21+. This event aims to serve as an opportunity to de-stress, relax and have some quality time with your friends in the days leading up to the Thanksgiving break.",
    img: [
      {
        src: "../images/events/friendsgiving/2019/1.jpg",
        alt: "Card front image",
      },
      { src: "../images/events/friendsgiving/2019/2.jpg" },
      { src: "../images/events/friendsgiving/2019/3.jpg" },
      { src: "../images/events/friendsgiving/2019/4.jpg" },
      { src: "../images/events/friendsgiving/2019/5.jpg" },
      { src: "../images/events/friendsgiving/2019/6.jpg" },
      { src: "../images/events/friendsgiving/2019/7.jpg" },
    ],
    completed: true,
  },
  {
    title: "Seathrough",
    date: "10/02/2021",
    time: "18:30 PDT",
    location: "HUB 214, UW",
    text: "Our annual dinner party that welcomes you to the IndoHuskies Community. An event that you do not want to miss out on as we’ll be providing delicious Indonesian food, an opportunity to create new friends, and win one-of-kind prizes by playing our games!",
    img: [
      {
        src: "../images/events/seathrough/2021/1.jpeg",
        alt: "Card front image",
      },
      { src: "../images/events/seathrough/2021/2.jpeg" },
      { src: "../images/events/seathrough/2021/3.jpeg" },
    ],
    completed: true,
  },
  {
    title: "Boba Tour",
    date: "09/28/2021",
    time: "14:00 PDT",
    location: "Red Square, UW",
    text: "Travel around Seattle’s most iconic landmarks while getting a taste of boba. A fun welcoming experience to meet new friends and familiarize with the day-to-day places and transport of a Seattleite.",
    img: [
      {
        src: "../images/events/bobatour/2021/bobatour3.jpg",
        alt: "Card front image",
      },
      { src: "../images/events/bobatour/2021/bobatour4.jpg" },
      { src: "../images/events/bobatour/2021/bobatour6.jpg" },
    ],
    completed: true,
  },
  {
    title: "Seattle 101",
    date: "08/14/2021",
    time: "13:00 PDT",
    location: "Zoom, Online",
    text: "Seattle 101 is our annual informative session designed to help prepare you to adjust to college life as easily as possible! This event aims to help you learn more about Seattle culture and the necessary tasks you have to do after arriving. Additionally, you will have the opportunity to learn about the experiences of our presenters, find and meet new friends during our networking session, and ask your burning questions during our QnA session, such as:  “Which bank should I register for?” “What’s the weather like?” “Where do people go to have fun?” etc.",
    img: [
      { src: "../images/events/seattle 101/2019/1.png" },
      { src: "../images/events/seattle 101/2019/2.png" },
    ],
    completed: true,
  },
];
export default eventsData;
